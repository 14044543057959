import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getPartnerTEDSTransactions, getSinglePartner, reset, resetPartnersState, unblockPartnerAccount } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
const PartnerOverview = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { partnerId } = useParams()

  const {
    partnerTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
    partner,
  } = useSelector((state) => state.partners)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
      partnerId,
    }
    dispatch(getSinglePartner(partnerId))
    dispatch(getPartnerTEDSTransactions(filterData))

    return () => {
      dispatch(resetPartnersState())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, partnerId])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  function formatTransactionType(transactionType) {
    const words = transactionType.split('-') // Split words on hyphens
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ') // Join them with spaces
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      {isLoading ? (
        <Spinner />
      ) : partner ? (
        <div className='bg-base-200 p-6 rounded-lg shadow-md mb-8'>
          <h2 className='text-2xl font-bold mb-4'>{partner.name}</h2>
          <h3 className='text-2xl my-4 my-10 font-semibold'>{partner.businessName}</h3>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Basic Info</h4>
              <p>
                <strong>Email:</strong> {partner.email}
              </p>
              <p>
                <strong>Business Category:</strong> {partner.businessCategory}
              </p>
              <p>
                <strong>Business Type:</strong> {partner.businessType}
              </p>
              <p>
                <strong>Date Joined:</strong> {new Date(partner.createdAt).toLocaleDateString()}
              </p>
              <p>
                <strong>Status:</strong> {partner.subscription?.isActive ? 'Active' : 'Inactive'}
              </p>
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Contact Information</h4>
              {partner.phone && partner.phone.landline && (
                <p>
                  <strong>Landline:</strong> {partner.phone.landline}
                </p>
              )}
              {partner.phone && partner.phone.mobile && (
                <p>
                  <strong>Mobile:</strong> {partner.phone.mobile}
                </p>
              )}
              <h4 className='text-lg font-semibold mt-4 text-red-700'>Address</h4>
              <p>{partner.address.addressLine1}</p>
              {partner.address.addressLine2 && <p>{partner.address.addressLine2}</p>}
              <p>
                {partner.address.city}, {partner.address.region} {partner.address.postcode}
              </p>
              <p>{partner.address.country}</p>
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Customer Metrics</h4>
              <p>
                <strong>Total Customers:</strong> {partner.keyMetrics?.totalCustomers}
              </p>
              <p>
                <strong>Stamps Administered:</strong> {partner.keyMetrics?.numberOfStampsAdministered}
              </p>
              <p>
                <strong>Campaigns:</strong> {partner.keyMetrics?.numberOfCampaigns}
              </p>
              <p>
                <strong>Rewards Administered:</strong> {partner.keyMetrics?.numberOfRewardsAdministered}
              </p>
              <p>
                <strong>Servers:</strong> {partner.keyMetrics?.numberOfServers}
              </p>
              <p>
                <strong>Messages Sent:</strong> {partner.keyMetrics?.numberOfMessagesSent}
              </p>
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Financial Information</h4>
              <p>
                <strong>Monthly Payments Made:</strong> {partner.subscription?.monthlyPaymentsMade}
              </p>
              <p>
                <strong>Yearly Payments Made:</strong> {partner.subscription?.yearlyPaymentsMade}
              </p>
              <p>
                <strong>Total Spent:</strong> £{(partner.subscription?.totalSpent / 100).toFixed(2)}
              </p>
              <p>
                <strong>Last Payment Date:</strong> {new Date(partner.subscription?.lastPaymentDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Plan:</strong> {partner.subscription?.plan}
              </p>
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>TEDS Information</h4>
              <p>
                <strong>TEDS Balance:</strong> {partner.wallet?.tedsBalance} (£{tedsToPounds(partner.wallet?.tedsBalance)})
              </p>
              <p>
                <strong>Mad TEDS Balance:</strong> {partner.wallet?.madTedsBalance} (£{tedsToPounds(partner.wallet?.madTedsBalance)})
              </p>
              <p>
                <strong>Transaction Count:</strong> {partner.wallet?.transactionCount}
              </p>
              <p>
                <strong>Stamps Sold For TEDS:</strong> {partner.keyMetrics?.numberOfStampsSoldForTeds}
              </p>
              <p>
                <strong>Stamps Bought For TEDS:</strong> {partner.keyMetrics?.numberOfStampsBoughtForTeds}
              </p>
              <p>
                <strong>Rewards Bought For TEDS:</strong> {partner.keyMetrics?.numberOfRewardsBoughtForTeds}
              </p>
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Agent Information</h4>
              {partner.agentReferralCode ? (
                <>
                  <p>
                    <strong>Agent Name:</strong> {partner.subscription?.agentName}
                  </p>
                  <p>
                    <strong>Agent Referral Code:</strong> {partner.agentReferralCode}
                  </p>
                </>
              ) : (
                <p>No agent assigned</p>
              )}
            </div>
            <div className='space-y-2 mt-6'>
              <h4 className='text-lg font-semibold text-red-700'>Point of Origin</h4>
              {partner.introducedBy ? (
                <>
                  <p>
                    <strong>Introduced By:</strong> {partner.introducedBy.businessName}
                  </p>
                  <p>
                    <strong>Referral Type:</strong> {partner.introducedBy.referralType}
                  </p>
                </>
              ) : (
                <p>No introduction data available</p>
              )}
            </div>

            {/* Add this new section for UTM data */}
            <div className='space-y-2 mt-6'>
              <h4 className='text-lg font-semibold text-red-700'>Marketing Campaign Data</h4>
              {partner.utmData ? (
                <>
                  <p>
                    <strong>Source:</strong> {partner.utmData.utm_source || 'N/A'}
                  </p>
                  <p>
                    <strong>Medium:</strong> {partner.utmData.utm_medium || 'N/A'}
                  </p>
                  <p>
                    <strong>Campaign:</strong> {partner.utmData.utm_campaign || 'N/A'}
                  </p>
                  <p>
                    <strong>Term:</strong> {partner.utmData.utm_term || 'N/A'}
                  </p>
                  <p>
                    <strong>Content:</strong> {partner.utmData.utm_content || 'N/A'}
                  </p>
                </>
              ) : (
                <p>No UTM data available</p>
              )}
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Bank Details for Sale of TEDS</h4>
              {partner.bankDetails ? (
                <>
                  <p>
                    <strong>Account Holder:</strong> {partner.bankDetails.accountHolderName}
                  </p>
                  <p>
                    <strong>Account Number:</strong> {partner.bankDetails.accountNumber}
                  </p>
                  <p>
                    <strong>Sort Code:</strong> {partner.bankDetails.sortCode}
                  </p>
                  <p>
                    <strong>Bank Name:</strong> {partner.bankDetails.bankName}
                  </p>
                  <p>
                    <strong>Status:</strong> {partner.bankDetails.verified ? 'Verified' : 'Unverified'}
                  </p>
                </>
              ) : (
                <p>No bank account linked</p>
              )}
            </div>

            <div className='space-y-2'>
              <h4 className='text-lg font-semibold text-red-700'>Account Status</h4>
              <p>
                <strong>Account Blocked:</strong> {partner.securitySettings?.accountIsBlocked ? 'Yes' : 'No'}
              </p>
              {partner.securitySettings?.accountIsBlocked && (
                <button onClick={() => dispatch(unblockPartnerAccount(partnerId))} className='btn btn-warning btn-sm mt-2'>
                  Unblock Partner Account
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a transaction by name'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md  mr-2 '
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction From</th>
              <th>Transaction TO</th>
              <th>Cost</th>
              <th>TEDS Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(transactions) || transactions.length === 0 ? (
              <tr>
                <td colSpan='6'>No Transactions found.</td>
              </tr>
            ) : (
              transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={transaction.id}>
                  <td className=''>{formatDate(transaction.createdAt)}</td>
                  <td className=''>{transaction.fromUser.userName}</td>
                  <td className=''>{transaction.toUser.userName}</td>
                  <td>{isNaN(transaction.cost) || transaction.cost == null ? 'N/A' : `${user.currency}${transaction.cost.toFixed(2)}`}</td>
                  <td className=''>
                    {transaction.transactionType === 'stamp-exchange' || transaction.transactionType === 'reward-exchange' ? (
                      <p className='text-red-500'>-{transaction.tedsAmount}</p>
                    ) : transaction.transactionType === 'teds-purchase' ? (
                      <span className='text-green-500'>+{transaction.tedsAmount}</span>
                    ) : transaction.transactionType === 'stamp-purchase' ? (
                      <span className='text-green-500'>+{transaction.tedsAmount}</span>
                    ) : (
                      transaction.tedsAmount
                    )}
                  </td>
                  <td className=''>{formatTransactionType(transaction.transactionType)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default PartnerOverview
