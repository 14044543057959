import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partnersService from './partnersService'

const initialState = {
  partners: [],
  referredPartners: [],
  partner: null,
  partnerTEDSTransactions: [],
  sales: [],
  dailyTEDSReport: [],
  failedRegistrations: [],
  otherBusinesses: [],
  partnerDetails: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// *Fetch Partners
export const getPartners = createAsyncThunk('partners/fetch', async (filterData, thunkAPI) => {
  console.log('filter data', filterData)
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getPartners(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Fetch single partner
export const getSinglePartner = createAsyncThunk('partners/fetchPartner', async (partnerId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getSinglePartner(token, partnerId)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch an agents partners via agentCode
export const getAgentsPartners = createAsyncThunk('partners/fetch-agents-partners', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getAgentsPartners(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Sales
export const getSales = createAsyncThunk('partners/fetch-sales', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getSales(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Daily Sales
export const getDailyTEDSReport = createAsyncThunk('partners/fetch-teds-report', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getDailyTEDSReport(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Failed registrations
export const getFailedRegistrations = createAsyncThunk('partners/fetch-failed-registrations', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getFailedRegistrations(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Other Businesses
export const getOtherBusinesses = createAsyncThunk('partners/fetch-other-businesses', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getOtherBusinesses(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Partner TEDS Transactions
export const getPartnerTEDSTransactions = createAsyncThunk('partners/fetch-transactions', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getPartnerTEDSTransactions(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Fetch referred partners
export const getReferredPartners = createAsyncThunk('partners/fetchReferredPartners', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.getReferredPartners(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Mark partner as contacted
export const markAsContacted = createAsyncThunk('partners/markAsContacted', async (partnerId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.markAsContacted(token, partnerId)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const unblockPartnerAccount = createAsyncThunk('partners/unblock', async (partnerId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnersService.unblockPartnerAccount(token, partnerId)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// The Slice
export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // Put your synchronous reducers here
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    resetPartnersState: (state) => {
      state.partnerTEDSTransactions = []
    },
    logout: (state) => {
      state.partners = []
      state.partnerTEDSTransactions = []
      state.failedRegistrations = []
      state.otherBusinesses = []
      state.sales = []
      state.dailyTEDSReport = []
      state.partnerDetails = {}
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartners.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPartners.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.partners = action.payload
    })
    builder.addCase(getPartners.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAgentsPartners.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAgentsPartners.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.partners = action.payload
    })
    builder.addCase(getAgentsPartners.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getSales.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSales.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.sales = action.payload
    })
    builder.addCase(getSales.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getDailyTEDSReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDailyTEDSReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.dailyTEDSReport = action.payload
    })
    builder.addCase(getDailyTEDSReport.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getFailedRegistrations.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFailedRegistrations.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.failedRegistrations = action.payload
    })
    builder.addCase(getFailedRegistrations.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getOtherBusinesses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getOtherBusinesses.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.otherBusinesses = action.payload
    })
    builder.addCase(getOtherBusinesses.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getPartnerTEDSTransactions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPartnerTEDSTransactions.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.partnerTEDSTransactions = action.payload
    })
    builder.addCase(getPartnerTEDSTransactions.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(getSinglePartner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSinglePartner.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.partner = action.payload
      })
      .addCase(getSinglePartner.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(getReferredPartners.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getReferredPartners.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.referredPartners = action.payload
      })
      .addCase(getReferredPartners.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(markAsContacted.pending, (state) => {
        state.isLoading = true
      })
      .addCase(markAsContacted.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const index = state.referredPartners.partners.findIndex((partner) => partner._id === action.payload._id)
        if (index !== -1) {
          state.referredPartners.partners[index] = action.payload
        }
      })
      .addCase(markAsContacted.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(unblockPartnerAccount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(unblockPartnerAccount.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        if (state.partner) {
          state.partner.securitySettings.accountIsBlocked = false
        }
      })
      .addCase(unblockPartnerAccount.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, logout, resetPartnersState } = partnersSlice.actions

export default partnersSlice.reducer
