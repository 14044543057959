import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { getPartners, reset } from '../features/partners/partnersSlice'
import { transferAgent, reset as agentsReset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const Partners = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [agentID, setAgentID] = useState('')
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [categoryVisibility, setCategoryVisibility] = useState({
    dateStatus: true,
    customerMetrics: true,
    financial: true,
    teds: true,
    other: true,
  })

  const { partners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)
  const {
    isSuccess: agentsIsSuccess,
    isLoading: agentsIsLoading,
    message: agentsMessage,
    isError: agentsIsError,
  } = useSelector((state) => state.agents)

  const columnCategories = {
    dateStatus: ['businessName', 'dateJoined', 'isActive', 'status'],
    customerMetrics: [
      'totalCustomers',
      'totalCustomersIntroduced',
      'totalPartnersIntroduced',
      'stampsAdministered',
      'campaigns',
      'rewardsAdministered',
    ],
    financial: ['monthlyPaymentsMade', 'yearlyPaymentsMade', 'totalSpent', 'lastPaymentDate', 'plan'],
    teds: [
      'tedsBalance',
      'madTedsBalance',
      'transactionCount',
      'numberOfStampsSoldForTeds',
      'numberOfStampsBoughtForTeds',
      'numberOfRewardsBoughtForTeds',
    ],
    other: ['messagesSent', 'assignAgent', 'servers', 'referralType'],
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const columnConfig = [
    { id: 'businessName', label: 'Business Name', isVisible: true },
    { id: 'dateJoined', label: 'Date Joined', isVisible: true },
    { id: 'isActive', label: 'isActive', isVisible: true },
    { id: 'totalCustomers', label: 'Total Customers', isVisible: true },
    { id: 'totalCustomersIntroduced', label: 'Customers Introduced', isVisible: true },
    { id: 'totalPartnersIntroduced', label: 'Partners Introduced', isVisible: true },
    { id: 'referralType', label: 'Point of Origin', isVisible: true },
    { id: 'stampsAdministered', label: 'Stamps Administered', isVisible: true },
    { id: 'campaigns', label: 'Campaigns', isVisible: true },
    { id: 'rewardsAdministered', label: 'Rewards Administered', isVisible: true },
    { id: 'servers', label: 'Servers', isVisible: true },
    { id: 'messagesSent', label: 'Messages Sent', isVisible: true },
    { id: 'monthlyPaymentsMade', label: 'Monthly Payments Made', isVisible: true },
    { id: 'yearlyPaymentsMade', label: 'Yearly Payments Made', isVisible: true },
    { id: 'totalSpent', label: 'Total Spent', isVisible: true },
    { id: 'lastPaymentDate', label: 'Last Payment Date', isVisible: true },
    { id: 'plan', label: 'Plan', isVisible: true },
    { id: 'tedsBalance', label: 'TEDS Balance', isVisible: true },
    { id: 'madTedsBalance', label: 'Mad TEDS Balance', isVisible: true },
    { id: 'transactionCount', label: 'Transaction Count', isVisible: true },
    { id: 'numberOfStampsSoldForTeds', label: 'Stamps Sold For TEDS', isVisible: true },
    { id: 'numberOfStampsBoughtForTeds', label: 'Stamps Bought For TEDS', isVisible: true },
    { id: 'numberOfRewardsBoughtForTeds', label: 'Rewards Bought For TEDS', isVisible: true },
    { id: 'assignAgent', label: 'Assign Agent', isVisible: true },
  ]

  const [columns, setColumns] = useState(columnConfig)

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map((col) => (col.id === columnId ? { ...col, isVisible: !col.isVisible } : col)))
  }

  const handleCategoryVisibilityChange = (category) => {
    const newVisibility = !categoryVisibility[category]
    setCategoryVisibility({ ...categoryVisibility, [category]: newVisibility })
    setColumns(columns.map((col) => (columnCategories[category].includes(col.id) ? { ...col, isVisible: newVisibility } : col)))
  }

  useEffect(() => {
    if (isError || agentsIsError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    }
    if (agentsIsSuccess && agentsMessage.success === true) {
      toast.success(agentsMessage.message)
      document.getElementById('assignAgentModal').checked = false
      dispatch(agentsReset())
      const filterData = {
        page: currentPage,
        limit: 15,
        searchTerm: debouncedSearchTerm,
      }
      dispatch(getPartners(filterData))
    } else if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message, agentsIsSuccess, agentsMessage, agentsIsError, currentPage, debouncedSearchTerm])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getPartners(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const assignAgentToPartner = (agentReferralCode, clientID) => {
    console.log(agentReferralCode, clientID)
    dispatch(transferAgent({ agentReferralCode, clientID }))
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      {/* Show and hide columns */}
      <div className='bg-gray-100 p-4 rounded-lg shadow mb-6'>
        <h1 className='font-bold text-xl my-4'> Show & Hide Columns</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          {Object.entries(columnCategories).map(([category, columnIds]) => (
            <div className='mt-8' key={category}>
              <h3 className='font-semibold text-lg mb-3 flex items-center'>
                <input
                  type='checkbox'
                  checked={categoryVisibility[category]}
                  onChange={() => handleCategoryVisibilityChange(category)}
                  className='form-checkbox h-5 w-5 mr-2'
                />
                {category.charAt(0).toUpperCase() + category.slice(1).replace(/([A-Z])/g, ' $1')}
              </h3>
              <div className='space-y-2'>
                {columns
                  .filter((col) => columnIds.includes(col.id))
                  .map((col) => (
                    <label key={col.id} className='flex items-center space-x-2'>
                      <input
                        type='checkbox'
                        checked={col.isVisible}
                        onChange={() => handleColumnVisibilityChange(col.id)}
                        className='form-checkbox h-5 w-5'
                      />
                      <span>{col.label}</span>
                    </label>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Search bar */}
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a partner by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs mr-2'
        />
      </div>

      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              {columns
                .filter((col) => col.isVisible)
                .map((col) => (
                  <th key={col.id}>{col.label}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(partners.partners) || partners.partners.length === 0 ? (
              <tr>
                <td colSpan='16'>No partners found.</td>
              </tr>
            ) : (
              partners.partners.map((partner, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={partner._id}>
                  {columns
                    .filter((col) => col.isVisible)
                    .map((col) => {
                      let content
                      switch (col.id) {
                        case 'businessName':
                          content = (
                            <>
                              <Link to={`/partner-overview/${partner._id}`} className=''>
                                <p className='font-bold'>{partner.businessName}</p>
                                <p className='text-gray-400 text-xs'>{partner.email}</p>
                              </Link>
                            </>
                          )
                          break
                        case 'dateJoined':
                          content = new Date(partner.createdAt).toLocaleDateString()
                          break
                        case 'isActive':
                          content = partner.subscription?.isActive ? 'Yes' : 'No'
                          break
                        case 'totalCustomers':
                          content = partner.keyMetrics?.totalCustomers
                          break
                        case 'totalCustomersIntroduced':
                          content = partner.keyMetrics?.totalCustomersIntroduced
                          break
                        case 'totalPartnersIntroduced':
                          content = partner.keyMetrics?.totalPartnersIntroduced
                          break
                        case 'stampsAdministered':
                          content = partner.keyMetrics?.numberOfStampsAdministered
                          break
                        case 'campaigns':
                          content = partner.keyMetrics?.numberOfCampaigns
                          break
                        case 'rewardsAdministered':
                          content = partner.keyMetrics?.numberOfRewardsAdministered
                          break
                        case 'servers':
                          content = partner.keyMetrics?.numberOfServers
                          break
                        case 'referralType':
                          content = partner.introducedBy?.referralType || 'N/A'
                          break
                        case 'messagesSent':
                          content = partner.keyMetrics?.numberOfMessagesSent
                          break
                        case 'numberOfStampsSoldForTeds':
                          content = partner.keyMetrics?.numberOfStampsSoldForTeds
                          break
                        case 'numberOfStampsBoughtForTeds':
                          content = partner.keyMetrics?.numberOfStampsBoughtForTeds
                          break
                        case 'numberOfRewardsBoughtForTeds':
                          content = partner.keyMetrics?.numberOfRewardsBoughtForTeds
                          break
                        case 'monthlyPaymentsMade':
                          content = partner.subscription?.monthlyPaymentsMade
                          break
                        case 'yearlyPaymentsMade':
                          content = partner.subscription?.yearlyPaymentsMade
                          break
                        case 'totalSpent':
                          content = `£${(partner.subscription?.totalSpent / 100).toFixed(2)}`
                          break
                        case 'lastPaymentDate':
                          content = new Date(partner.subscription?.lastPaymentDate).toLocaleDateString()
                          break
                        case 'plan':
                          content = partner.subscription?.plan
                          break
                        case 'assignAgent':
                          content = partner.agentReferralCode ? (
                            <>
                              <Link to={`/agent-overview/${partner.subscription?.referringAgentID}`} className='btn btn-secondary btn-sm'>
                                {partner.subscription?.agentName}
                              </Link>
                              <p className='text-gray-400 text-base font-bold'>{partner.agentReferralCode}</p>
                            </>
                          ) : (
                            <label onClick={() => setSelectedPartner(partner)} htmlFor='assignAgentModal' className='btn btn-primary btn-sm'>
                              Assign Agent
                            </label>
                          )
                          break
                        case 'tedsBalance':
                          content = (
                            <div className='flex flex-col'>
                              <div className='text-lg'>{partner?.wallet?.tedsBalance}</div>
                              <div className='text-xs'>£{tedsToPounds(partner?.wallet?.tedsBalance)} </div>
                            </div>
                          )
                          break
                        case 'madTedsBalance':
                          content = (
                            <div className='flex flex-col'>
                              <div className='text-lg'>{partner?.wallet?.madTedsBalance}</div>
                              <div className='text-xs'>£{tedsToPounds(partner?.wallet?.madTedsBalance)} </div>
                            </div>
                          )
                          break
                        case 'transactionCount':
                          content = `${partner?.wallet?.transactionCount}`
                          break
                        default:
                          content = 'N/A'
                      }
                      return <td key={`${partner._id}-${col.id}`}>{content}</td>
                    })}
                </tr>
              ))
            )}
          </tbody>
        </table>

        <input type='checkbox' id='assignAgentModal' className='modal-toggle' />
        <div className='modal'>
          <div className='modal-box'>
            <h3 className='font-bold text-lg'>Assign Agent</h3>
            <div className='py-4'>
              <input
                type='text'
                placeholder='Enter Agent Code To Assign To Partner'
                value={agentID}
                onChange={(e) => setAgentID(e.target.value)}
                className='input w-full mb-4 border-red-100'
              />
            </div>
            <div className='modal-action'>
              <button className='btn' onClick={() => assignAgentToPartner(agentID, selectedPartner._id)} disabled={agentsIsLoading}>
                Submit
              </button>
              <label htmlFor='assignAgentModal' className='btn'>
                Close
              </label>
            </div>
          </div>
        </div>
      </div>
      <Pagination totalPages={partners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Partners
