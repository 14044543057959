import React from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAgent, reset } from '../features/agents/agentsSlice'
import BackButton from '../components/BackButton'

const AgentOverview = () => {
  const dispatch = useDispatch()

  const { agentId } = useParams()
  const { agent, isError, isSuccess, isLoading, message } = useSelector((state) => state.agents)
  const { user } = useSelector((state) => state.auth)

  const {
    name,
    email,
    address: { addressLine1: addressLine1, addressLine2: addressLine2, city: city, region: region, country: country, postcode: postcode } = {}, // Defaulting to an empty object in case `address` is not provided
    businessAddress: {
      addressLine1: businessAddressLine1,
      addressLine2: businessAddressLine2,
      city: businessCity,
      region: businessRegion,
      country: businessCountry,
      postcode: businessPostcode,
    } = {},
    phone: { landline: landline, mobile: mobile } = {},
  } = agent.businessDetails || {}

  useEffect(() => {
    dispatch(getAgent(agentId))
  }, [dispatch, agentId])

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  // Assuming agent has properties like dailySales, weeklySales, and monthlySales
  const { dailySales = [], weeklySales = [], monthlySales = [], yearlySales = [] } = agent || {}

  const generateLastNDays = (n) => {
    const dates = []
    const today = new Date()
    for (let i = 0; i < n; i++) {
      const date = new Date(today)
      date.setDate(today.getDate() - i)
      dates.push(date.toISOString().split('T')[0])
    }
    return dates
  }

  const generateLastNWeeks = (n) => {
    const weeks = []
    const today = new Date()
    for (let i = 0; i < n; i++) {
      const weekStart = new Date(today)
      weekStart.setDate(today.getDate() - 7 * i)
      const weekNumber = Math.ceil(((weekStart - new Date(weekStart.getFullYear(), 0, 1)) / 86400000 + 1) / 7)
      weeks.push(`${weekStart.getFullYear()}-Week${weekNumber}`)
    }
    return weeks
  }

  const generateAllYears = () => {
    const currentYear = new Date().getFullYear()
    // Assuming yearlySales is sorted in descending order by year
    const startYear = yearlySales.length > 0 && yearlySales[0].yearLabel ? parseInt(yearlySales[0].yearLabel) : currentYear

    const years = []
    for (let i = startYear; i <= currentYear; i++) {
      years.push(String(i))
    }
    return years
  }

  // Find the latest month from monthlySales
  const latestMonthInSales =
    monthlySales.length > 0 && monthlySales[0].monthLabel ? monthlySales[0].monthLabel : new Date().toISOString().split('T')[0].slice(0, 7)

  // Modify generateLastNMonths to accept a start date
  const generateLastNMonths = (n) => {
    const months = []
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1 // JavaScript months are 0-11, so add 1

    for (let i = 0; i < n; i++) {
      months.push(`${year}-${String(month).padStart(2, '0')}`)
      month--
      if (month === 0) {
        month = 12
        year--
      }
    }
    return months
  }
  const getDayName = (dateStr) => {
    const date = new Date(dateStr)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const formattedDate = date.toLocaleDateString('en-GB') // This will format as DD/MM/YYYY
    return `${days[date.getDay()]}, ${formattedDate}`
  }

  // Generate last 7 days
  const last7Days = generateLastNDays(7)

  // Generate last 5 weeks
  const last5Weeks = generateLastNWeeks(5)

  const last12Months = generateLastNMonths(12, latestMonthInSales)

  const allYears = generateAllYears()

  function getMonthNameFromLabel(monthLabel) {
    const [year, month] = monthLabel.split('-')
    const date = new Date(year, month - 1) // month - 1 because months in JavaScript are 0-indexed
    const monthName = date.toLocaleString('en-GB', { month: 'long' })
    return `${monthName} ${year}`
  }

  return (
    <>
      <div className='flex '>
        {' '}
        <BackButton />
      </div>

      {/* Agent's Business Details */}

      <div className='my-6 mx-3 p-4 border rounded shadow-lg bg-white '>
        <h2 className='font-bold text-2xl mb-4 text-gray-800'>Agent's Business Details</h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm md:text-base'>
          <div className='mb-4'>
            <h3 className='font-semibold text-gray-700'>Name:</h3>
            <p className='text-gray-600'>{agent.name || 'N/A'}</p>
          </div>
          <div className='mb-4'>
            <h3 className='font-semibold text-gray-700'>Email:</h3>
            <p className='text-gray-600'>{agent.email || 'N/A'}</p>
          </div>
          <div className='mb-4'>
            <h3 className='font-semibold text-gray-700'>Phone - Landline:</h3>
            <p className='text-gray-600'>{landline || 'N/A'}</p>
          </div>
          <div className='mb-4'>
            <h3 className='font-semibold text-gray-700'>Phone - Mobile:</h3>
            <p className='text-gray-600'>{mobile || 'N/A'}</p>
          </div>
          <div className='col-span-2 mb-4'>
            <h3 className='font-semibold text-gray-700'>Address:</h3>
            <p className='text-gray-600'>{`${addressLine1 || ''} ${addressLine2 || ''}`.trim() || 'N/A'}</p>
            <p className='text-gray-600'>{[city, region].filter(Boolean).join(', ') || 'N/A'}</p>
            <p className='text-gray-600'>{[country, postcode].filter(Boolean).join(' - ') || 'N/A'}</p>
          </div>
          <div className='col-span-2 mb-4'>
            <h3 className='font-semibold text-gray-700'>Business Address:</h3>
            <p className='text-gray-600'>{`${businessAddressLine1 || ''} ${businessAddressLine2 || ''}`.trim() || 'N/A'}</p>
            <p className='text-gray-600'>{[businessCity, businessRegion].filter(Boolean).join(', ') || 'N/A'}</p>
            <p className='text-gray-600'>{[businessCountry, businessPostcode].filter(Boolean).join(' - ') || 'N/A'}</p>
          </div>
          <div className='col-span-2 mb-10'>
            <h1 className='font-bold text-xl'>Agent Referral Code - {agent.agentReferralCode}</h1>
          </div>
        </div>
      </div>
      <div className='overflow-x-auto flex flex-col'>
        {/* Last 7 Days Sales (Daily Breakdown) */}
        <p className='font-bold text-xl mt-20'>Daily Sales Breakdowns (Last 7 Days)</p>
        <table className='md:mx-20 table table-zebra my-6 flex justify-center'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Yearly Renewals</th>
              <th>Monthly Renewals</th>
              <th>Yearly New Subs</th>
              <th>Monthly New Subs</th>
              <th>Commission Earned</th>
              {user.role === 'AdminUser' && <th>Amount</th>}
            </tr>
          </thead>
          <tbody>
            {last7Days.map((day, index) => {
              const sale = dailySales.find((s) => s.dayLabel === day) || {}
              return (
                <tr key={index}>
                  <td>{getDayName(day)}</td>
                  <td>{sale.yearlySubscriptionRenewal || 0}</td>
                  <td>{sale.monthlySubscriptionRenewal || 0}</td>
                  <td>{sale.yearlyNewSubscription || 0}</td>
                  <td>{sale.monthlyNewSubscription || 0}</td>
                  <td>
                    {sale.totalCommission ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                  </td>
                  {user.role === 'AdminUser' && (
                    <td>{sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* Last 5 Weeks Sales (Weekly Breakdown) */}
        <p className='font-bold text-xl mt-20'>Weekly Sales Breakdowns (Each week counts from Mon-Sun)</p>
        <table className='table table-zebra my-6 md:mx-20'>
          <thead>
            <tr>
              <th>Week Starting</th>
              <th>Yearly Renewals</th>
              <th>Monthly Renewals</th>
              <th>Yearly New Subs</th>
              <th>Monthly New Subs</th>
              <th>Commission Earned</th>
              {user.role === 'AdminUser' && <th>Amount</th>}
            </tr>
          </thead>
          <tbody>
            {last5Weeks.map((weekStart, index) => {
              const sale = weeklySales.find((s) => s.weekLabel.startsWith(weekStart)) || {}
              return (
                <tr key={index}>
                  <td>{weekStart}</td>
                  <td>{sale.yearlySubscriptionRenewal || 0}</td>
                  <td>{sale.monthlySubscriptionRenewal || 0}</td>
                  <td>{sale.yearlyNewSubscription || 0}</td>
                  <td>{sale.monthlyNewSubscription || 0}</td>
                  <td>
                    {sale.totalCommission ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                  </td>
                  {user.role === 'AdminUser' && (
                    <td>{sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* Last 12 Months Sales (Monthly Breakdown) */}
        <p className='font-bold text-xl mt-20'>Monthly Sales Breakdown</p>
        <table className='table table-zebra my-6 md:mx-20 mb-20'>
          <thead>
            <tr>
              <th>Month</th>
              <th>Yearly Renewals</th>
              <th>Monthly Renewals</th>
              <th>Yearly New Subs</th>
              <th>Monthly New Subs</th>
              <th>Commission Earned</th>
              {user.role === 'AdminUser' && <th>Amount</th>}
            </tr>
          </thead>
          <tbody>
            {last12Months.map((month, index) => {
              const sale = monthlySales.find((s) => s.monthLabel === month) || {}
              return (
                <tr key={index}>
                  <td>{getMonthNameFromLabel(month)}</td>
                  <td>{sale.yearlySubscriptionRenewal || 0}</td>
                  <td>{sale.monthlySubscriptionRenewal || 0}</td>
                  <td>{sale.yearlyNewSubscription || 0}</td>
                  <td>{sale.monthlyNewSubscription || 0}</td>
                  <td>
                    {sale.totalCommission ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                  </td>
                  {user.role === 'AdminUser' && (
                    <td>{sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>

        {/* Yearly Sales Breakdown */}
        <p className='font-bold text-xl mt-20'>Yearly Sales Breakdown</p>
        <table className='table table-zebra my-6 md:mx-20 mb-20'>
          <thead>
            <tr>
              <th>Year</th>
              <th>Yearly Renewals</th>
              <th>Monthly Renewals</th>
              <th>Yearly New Subs</th>
              <th>Monthly New Subs</th>
              <th>Commission Earned</th>
              {user.role === 'AdminUser' && <th>Amount</th>}
            </tr>
          </thead>
          <tbody>
            {allYears.map((year, index) => {
              const sale = yearlySales.find((s) => s.yearLabel === year) || {}
              return (
                <tr key={index}>
                  <td>{year}</td>
                  <td>{sale.yearlySubscriptionRenewal || 0}</td>
                  <td>{sale.monthlySubscriptionRenewal || 0}</td>
                  <td>{sale.yearlyNewSubscription || 0}</td>
                  <td>{sale.monthlyNewSubscription || 0}</td>
                  <td>
                    {sale.totalCommission ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                  </td>
                  {user.role === 'AdminUser' && (
                    <td>{sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AgentOverview
